<template>
  <div class="all">
    <user-list-item v-for="item in list" :data="item" />
    <Spin v-if="loading" fix>
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>加载中...</div>
    </Spin>
  </div>
</template>

<script>
import UserListItem from './UserListItem'

export default {
  name: 'UserList',
  props: {
    list: Array,
    loading: Boolean,
  },
  components: {
    UserListItem,
  },
}
</script>

<style scoped>
.all {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
</style>
