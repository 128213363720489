<template>
  <div @click="onClick" class="itemAll">
    <img :src="rImg" />
    <div class="info">
      <span class="title">{{ data.appCompanyName }}</span>
      <div class="type">
        <span>{{ data.appProduct }}</span>
        <span>{{ data.appindustry }}</span>
      </div>
      <div class="area">
        <img src="~assets/img/public/aPoint.png" />
        <span>{{ rArea }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserListItem',
  props: {
    data: Object,
  },
  methods: {
    onClick() {
      console.log(this.data)
      this.$router.push({
        name: 'UsersPageDel',
      })
    },
  },
  computed: {
    rImg() {
      return this.data.appcompanypics && this.data.appcompanypics.length > 0
        ? this.$config.personalCenter.yellowPage +
            this.data.appcompanypics.split('|||')[0]
        : require('assets/img/public/noImg.png')
    },
    rArea() {
      return this.data.appprovince + this.data.appcity
    },
  },
}
</script>

<style scoped>
.itemAll {
  display: flex;
  align-items: center;
  width: 350px;
  height: 90px;
  margin-bottom: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.itemAll:hover .title {
  color: #25449a;
  text-decoration: underline;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: 10px;
}
.itemAll > img {
  width: 80px;
  height: 80px;
}

.title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #303133;
}

.type span {
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #25449a;
  width: 70px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #dcedff;
  border-radius: 4px;
  margin-right: 18px;
}

.area span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  opacity: 0.9;
}
.area img {
  width: 12px;
  height: 15px;
  margin: -4px 7px 0 0;
}
</style>
