<template>
  <div class="all w1400">
    <filter-item
      title="行业"
      @onClick="fClick(1, $event)"
      :id="filters.industry"
      :list="filters.induList"
    />
    <filter-item
      title="类型"
      @onClick="fClick(2, $event)"
      :id="filters.type"
      :list="filters.typeList"
    />
    <filter-item
      title="地区"
      v-if="isReady"
      @onClick="fClick(3, $event)"
      :id="filters.area"
      :list="areaList"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import { getProvinceInfo } from 'network/usersPage'

export default {
  name: 'FilterScreen',
  data() {
    return {
      areaList: [],
      isReady: false,
    }
  },
  created() {
    this.getProvinceInfo()
  },
  methods: {
    ...mapMutations('usersPage', [
      'setIndustry',
      'setType',
      'setArea',
      'setAreaList',
    ]),
    fClick(e, data) {
      switch (e) {
        case 1:
          this.setIndustry(data.id)
          this.setType(0)
          this.setArea(0)
          this.getProvinceInfo()
          break
        case 2:
          this.setType(data.id)
          this.setArea(0)
          this.getProvinceInfo()
          break
        case 3:
          this.setArea(data.id)
          break

        default:
          break
      }
      this.$emit('onClick')
    },
    /* api相关 */
    getProvinceInfo() {
      let iID = this.filters.industry
      let tID = this.filters.type
      let data = {
        appindustry: this.filters.induList[iID - 1],
        appProduct: this.filters.typeList[tID - 1],
      }
      getProvinceInfo(data).then((res) => {
        if (res.code == 0) {
          this.areaList = res.data
          this.isReady = true
        }
      })
    },
  },
  computed: {
    ...mapState('usersPage', ['filters']),
  },
}
</script>

<style scoped>
.all {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 134px;
  padding: 25px;
  margin: 20px 0 30px;
  border: 1px solid #e6ebf5;
}
</style>
