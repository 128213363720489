import { request } from './request'

// 获取筛选条件（地区）
export function getProvinceInfo(data) {
  return request({
    url: '/txapi/getProvinceInfo',
    method: 'get',
    params: data
  })
}

// 获取用户信息
export function getUserInfoNoCheck(data) {
  return request({
    url: '/txapi/getUserInfoNoCheck',
    method: 'get',
    params: data
  })
}
