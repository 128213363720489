<template>
  <div class="content w1400">
    <filter-screen @onClick="filterClk" />
    <user-list :list="list" :loading="loading" />
    <advertisement />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getUserInfoNoCheck } from 'network/usersPage'

import FilterScreen from './layout/FilterScreen'
import UserList from './layout/UserList'

export default {
  name: 'UsersPage',
  data() {
    return {
      list: [],
      loading: false,
    }
  },
  created() {
    this.getUserInfoNoCheck()
  },
  methods: {
    filterClk() {
      this.getUserInfoNoCheck()
    },
    /* api相关 */
    getUserInfoNoCheck() {
      let iID = this.filters.industry
      let tID = this.filters.type
      let aID = this.filters.area
      let data = {
        appindustry: this.filters.induList[iID - 1],
        appProduct: this.filters.typeList[tID - 1],
        province: parseInt(aID) == 0 ? null : aID,
        pageSize: 32,
        page: 1,
      }
      this.loading = true
      getUserInfoNoCheck(data).then((res) => {
        this.loading = false
        if (res.code == 0) {
          this.list = res.data.list
        }
      })
    },
  },
  components: {
    FilterScreen,
    UserList,
  },
  computed: {
    ...mapState('usersPage', ['filters']),
  },
}
</script>

<style scoped>
.content {
  padding-bottom: 50px;
}
</style>
